import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministradoresComponent } from './administradores/administradores.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdministradorDetalleComponent } from './administrador-detalle/administrador-detalle.component';
import { AdministradorVisualizacionComponent } from './administrador-visualizacion/administrador-visualizacion.component';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  declarations: [AdministradoresComponent, AdministradorDetalleComponent, AdministradorVisualizacionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  exports: [
    AdministradoresComponent
  ]
})
export class AdminModule { }
