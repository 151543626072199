import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-administrador-detalle',
  templateUrl: './administrador-detalle.component.html',
  styleUrls: ['./administrador-detalle.component.scss']
})
export class AdministradorDetalleComponent implements OnInit {

  @Input() elementSend: any;

  id: any;
  sub: any;

  response: any;
  idPost: number;
  
  //constructor() { }
  private routeSub: Subscription;
  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {

    console.log(this.route.params);

    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) //log the entire params object
      console.log(params['id']) //log the value of id
      this.idPost = params['id'];
    });

    let formSend = new FormData();
    formSend.append("id", "hector");
    formSend.append("nombre", "hector");
    formSend.append("apellido", "aguilar");


    //this.http.post("https://api.github.com/users/" + this.userName, formSend)
    //this.http.post("https://api-aguitech.casidios.com/api_test.php?aguitech=backend&" + this.userName, formSend)
    //this.http.post("https://api-aguitech.casidios.com/api_test2.php?aguitech=backend", formSend)
    this.http.post("https://api-aguitech.casidios.com/categoria_detail.php?aguitech=backend&id_categoria=" + this.idPost, formSend)
    
    .subscribe((response) => {
      this.response = response;
      console.log("respuesta");
      console.log(this.response.list);
      console.log(this.response.list[0]);

      console.log(response);
      /*
      let text = response.text();
      let json = response.json();

      this.Notify(text);
      let jsonAsText = JSON.stringify(json)
      this.Notify(jsonAsText);

      */

    })
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}
