import { AfterViewInit, Component, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { MainTableDataSource, EXAMPLE_DATA } from './main-table-datasource';

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.css']
})
export class MainTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() dataTable;
  dataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name'];

  ngAfterViewInit() {
    this.dataSource = new MainTableDataSource(this.paginator, this.sort);
    this.dataSource = EXAMPLE_DATA;
  }
}
