import { Injectable } from '@angular/core';

/*
@Injectable({
  providedIn: 'root'
})
*/
@Injectable()
export class ServiciosService {

  /*
  constructor() { }
  */
  printToConsole(arg){
    console.log("Arguments");
    console.log(arg);
  }
  

}
