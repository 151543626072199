import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})

export class UsuariosComponent implements OnInit {

  
  public tableData1: TableData;

  constructor() { }

  ngOnInit() {
    this.tableData1 = {
      headerRow: [ '#', 'Name', 'Razon Social', 'Actions'],
      dataRows: [
        ['1', 'Fallas', 'En mantenimiento el elevador','btn-link'],
        ['2', 'Problemas', 'Roof garden sucio', 'btn-link'],
        ['3', 'Seguridad', 'No hay paso en los peatones', 'btn-link'],
        ['4', 'Texto', 'Y mas texto', 'btn-link'],
        ['5', 'Paul Dickens', 'Communication', 'btn-link']
      ]
   };
  }

}
export interface TableData {
  headerRow: string[];
  dataRows: string[][];
}