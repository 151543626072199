import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-administrador-visualizacion',
  templateUrl: './administrador-visualizacion.component.html',
  styleUrls: ['./administrador-visualizacion.component.scss']
})
export class AdministradorVisualizacionComponent implements OnInit {

  @Input() element: any;

  constructor() { }

  ngOnInit() {
  }

}
