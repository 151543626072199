import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-administradores',
  templateUrl: './administradores.component.html',
  styleUrls: ['./administradores.component.scss']
})
export class AdministradoresComponent implements OnInit {

  categoria: string = "";
  userName: string = "";
  response: any;
  myDataArray = [];

  //responseJson: any;
  //responseText: any;
  
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.myDataArray[0] = "hola";
    this.myDataArray[1] = "hola";
    /*
    let obs = this.http.get("https://api.github.com/users/aguitech");
    //console.log(response);
    obs.subscribe((response) => console.log(response));
    */
  }
  search(){

    let formSend = new FormData();
    formSend.append("id", "hector");
    formSend.append("nombre", "hector");
    formSend.append("apellido", "aguilar");


    //this.http.post("https://api.github.com/users/" + this.userName, formSend)
    //this.http.post("https://api-aguitech.casidios.com/api_test.php?aguitech=backend&" + this.userName, formSend)
    this.http.post("https://api-aguitech.casidios.com/api_test2.php?aguitech=backend", formSend)
    
    .subscribe((response) => {
      this.response = response;
      console.log("respuesta");
      console.log(this.response.list);
      console.log(this.response.list[0]);

      console.log(response);
      /*
      let text = response.text();
      let json = response.json();

      this.Notify(text);
      let jsonAsText = JSON.stringify(json)
      this.Notify(jsonAsText);

      */

    })
  }
  guardar(){
    let formSend = new FormData();
    formSend.append("id", "hector");
    formSend.append("nombre", "hector");
    formSend.append("categoria", this.categoria);

    //this.http.post("https://api.github.com/users/" + this.categoria, formSend)
    //this.http.post("https://api-aguitech.casidios.com/api_test2.php?aguitech=backend" + this.categoria, formSend)
    this.http.post("https://api-aguitech.casidios.com/categoria_create.php?aguitech=backend", formSend)
    
    .subscribe((response) => {
      console.log(response);

      this.search();
      /*
      this.response = response;
      //this.response2 = response.list;
      console.log("respuesta POST");
      console.log(this.response);
      */

      //console.log(this.response2);
    })
  }

}
