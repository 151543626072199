import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../servicios.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {

  /*
  constructor() {

    
    let svc = new ServiciosService();
    //svc.printToConsole;
    svc.getApiResponse();
  }

  Es lo mismo que a continuacion
  */

  /*
 constructor(svc: ServiciosService) {
    //svc.getApiResponse();
    svc.printToConsole("got the service");
  }

  Mejorando la logica
  */
 /*
 constructor(private svc: ServiciosService) {
    //svc.getApiResponse();
    this.svc.printToConsole("got the service!");
  }

  Ahora agregamos HttpClient
  */

  userName: string = "";
  response: any;
  response2: any;

  constructor(private http: HttpClient) {
    //svc.getApiResponse();
    //this.svc.printToConsole("got the service!");
  }


  /*
  ngOnInit() {
    let response = this.http.get("https://api.github.com/users/aguitech");
    console.log(response);
  }

  Agregando un observable
  */
 /*
  ngOnInit() {
    let obs = this.http.get("https://api.github.com/users/aguitech");
    //console.log(response);
    obs.subscribe(() => console.log("Got the response"));
  }

  Mejoramos la funcionalidad con la respuesta del servidor
  */
 /*
  ngOnInit() {
    let obs = this.http.get("https://api.github.com/users/aguitech");
    //console.log(response);
    obs.subscribe((response) => console.log(response));
  }
  */
  ngOnInit() {
    /*
    let obs = this.http.get("https://api.github.com/users/aguitech");
    //console.log(response);
    obs.subscribe((response) => console.log(response));
    */
  }
  search(){
    this.http.get("https://api.github.com/users/" + this.userName)
    .subscribe((response) => {
      this.response = response;
      //this.response2 = response.list;
      console.log("respuesta");
      console.log(this.response);

      //console.log(this.response2);
    })
  }

}
