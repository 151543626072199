<p>
  administrador-detalle works!
</p>
{{elementSend}}

{{ response }}

{{ response.list[0].id_categoria }}
{{ response.list[0].categoria }}
{{ response.list[0].color }}

