<p>
  translation works!
</p>
<div class="contenedor">
    <h1>--- {{ 'principal.texto' | translate }}</h1>
    <h1>--- {{ 'principal.texto2' | translate }}</h1>
    <h1>--- {{ 'principal.texto3' | translate }}</h1>
    <div class="contenedor-botones">
      <button [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')">Español</button>
      <button [ngClass]="{'active': activeLang == 'en'}" (click)="cambiarLenguaje('en')">Inglés</button>
      <button [ngClass]="{'active': activeLang == 'jp'}" (click)="cambiarLenguaje('jp')">Japonés</button>
    </div>
  </div>