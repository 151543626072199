import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { HttpClient } from '@angular/common/http';
//import {enableProdMode} from '@angular/core';
//enableProdMode();


@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.scss']
})
export class EquipoComponent implements OnInit {

  /*
  constructor() { }

  ngOnInit() {
  }
  */
  
  //public items: Item[] = [];

  private urlapi = 'https://api.exchangeratesapi.io/latest';
  public currentEuroRates: any = null;

  constructor(private httpClient:HttpClient) {}

  ngOnInit() {
    this.getCurrentEuroRates();
  }

  private getCurrentEuroRates() {
    const currencies = 'USD,GBP,CHF,JPY';
    const url = `${this.urlapi}?symbols=${currencies}`;
    console.log(url);

    this.httpClient
      .get(url)
      .subscribe(apiData => (this.currentEuroRates = apiData));
  }

    
/*
    let formDataSend = new FormData(); 



    let inc_new = 0;

    formDataSend.append('idComponente', '1');
    formDataSend.append('nombreTipoComponente', 'PERSONA');
    formDataSend.append('nombreTipoImagen', 'PERFIL');

    console.log("EEnviadno parametes");
    */
    /*
    parameters.parameters.imagenes.forEach((value, key) => {
      console.log("PREV SEND IMG");
      console.log(key + " " + value)
      console.log(key + " " + value.name)


      let images_index = 'imagenes[' + inc_new + ']'

      formDataSend.append('imagenes', value, value.name);

      inc_new++;
    });
    */

    

    /*
    return this._http
      //.post<ResponseVO<any[]>>(url, parameters.parameters, httpOp)
      .post<ResponseVO<any[]>>(url, formDataSend, httpOp)
      .pipe(catchError(this._dataService.handleError));

      */

      
    /*
    let formDataSend = new FormData(); 

    let inc_new = 0;
    parameters.parameters.imagenes.forEach((value, key) => {
      console.log("PREV SEND IMG");
      console.log(key + " " + value)
      console.log(key + " " + value.name)


      let images_index = 'imagenes[' + inc_new + ']'

      formDataSend.append('imagenes', value, value.name);

      inc_new++;
    });

    formDataSend.append('idComponente', '1');
    formDataSend.append('nombreTipoComponente', 'PERSONA');
    formDataSend.append('nombreTipoImagen', 'PERFIL');

    console.log("EEnviadno parametes");

    return this._http
      //.post<ResponseVO<any[]>>(url, parameters.parameters, httpOp)
      .post<ResponseVO<any[]>>(url, formDataSend, httpOp)
      .pipe(catchError(this._dataService.handleError));


    */

    
    /*
    $http.get('/someUrl', config).then(successCallback, errorCallback);
    $http.post('/someUrl', data, config).then(successCallback, errorCallback);


    this.httpClient
      .get(url)
      .subscribe(apiData => (
        this.currentEuroRates = apiData
        ));
        */
      
  }
  
  /*
.subscribe(apiData => {
        this.currentEuroRates = apiData;
        console.log("current euro");
        console.log(this.currentEuroRates);
      });


      .subscribe(apiData => (
        this.currentEuroRates = apiData
        ));
        */




/*
private urlapi
    = 'https://api.exchangeratesapi.io/latest';
  public currentEuroRates: any = null;

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.getCurrentEuroRates();
  }

  private getCurrentEuroRates() {
    const currencies = 'USD,GBP,CHF,JPY';
    const url = `${this.urlapi}?symbols=${currencies}`;
    this.httpClient
      .get(url)
      .subscribe(apiData => (this.currentEuroRates = apiData));
  }
*/